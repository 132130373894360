// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Carbonfootprintcalculator from "../../blocks/carbonfootprintcalculator/src/Carbonfootprintcalculator";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import Arvrrealityfeelforthehouse from "../../blocks/arvrrealityfeelforthehouse/src/Arvrrealityfeelforthehouse";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import DataStorage from "../../blocks/datastorage/src/DataStorage";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";



const routeMap = {
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Carbonfootprintcalculator:{
 component:Carbonfootprintcalculator,
path:"/Carbonfootprintcalculator"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
Arvrrealityfeelforthehouse:{
 component:Arvrrealityfeelforthehouse,
path:"/Arvrrealityfeelforthehouse"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;